import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useProducts from '../../utils/useProducts';
import { ProductIds } from '../../models/ProductEnums';
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";

function HealthEssentialsCta() {
    const navigate = useNavigate();
    const { products, loading } = useProducts();
    const [hasHealthEssentials, setHasHealthEssentials] = useState<boolean>(false);

    const navigateToHealthEssentials = () => navigate("/healthEssentials");
    const navigateToDashboard = () => navigate("/");

    useEffect(() => {
        if (products.length > 0) {
            const hasHealthEssentialsProduct = products.some(p => p.productUuid === ProductIds.HealthEssentials);
            setHasHealthEssentials(hasHealthEssentialsProduct);
        }
    }, [products]);
    
    return (
        <LoadingSpinner loading={loading || !hasHealthEssentials}>
            {hasHealthEssentials && (
                <div className="bg-lime-turk flex shadow-tile rounded-lg p-md xl:p-lg">
                    <div>
                        <h2 className="font-sans-co font-bold mb-md text-h3 sm:text-h2 text-lime-blue">
                            For an urgent health concern, speak to a clinician at Health Essentials
                        </h2>
                        <div className="text-p2 sm:text-p0 mb-md">
                            <span className="font-medium">Book a clinician in as soon as 30 minutes...</span>
                        </div>
                        <button onClick={navigateToHealthEssentials}
                                className="navigationButton text-p1 px-lg lg:px-10 py-sm pt-xs w-fit font-sans-co">
                            Go to Health Essentials
                        </button>
                    </div>
                </div>
            )}
            {!hasHealthEssentials && (
                <div className="bg-lime-turk flex shadow-tile rounded-lg p-md xl:p-lg">
                    <button onClick={navigateToDashboard}
                            className="navigationButton text-p1 px-lg lg:px-10 py-sm pt-xs w-fit font-sans-co">
                        Go back to dashboard
                    </button>
                </div>
            )}
        </LoadingSpinner>
    );
}

export default HealthEssentialsCta;
