import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import imgHandsAroundShield from "../../images/health-tracker/safe-hands.svg"
import imgDoctor from "../../images/health-tracker/smiling-female-doctor-with-glasses.png";
import imgInfo from "../../images/info_icon.png";
import {composeUrl} from "../../utils/api";
import HealthTrackerBanner from "./HealthTrackerBanner";

function ProductInfo() {
    const auth = useAuth();
    const navigate = useNavigate();

    const navigateToHome = () => navigate("/HealthTracker", {state: {showHealthTrackerHome: true}});

    // check if states can be used to switch between different results pages
    const navigateToPreviousResults = () => navigate("/HealthTracker/results", {state: {showHealthTrackerHome: false}});
    const navigateToDocs = () => navigate("/documents");
    const summaryUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+Service+Summary.pdf";
    const faqsUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+FAQ.pdf";

    const [loading, setLoading] = useState<boolean>(true);
    const [nextAllowedScan, setNextAllowedScan] = useState<string>();

    const [acceptedDataNotShared, setAcceptedDataNotShared] = useState<boolean>(false);

    function acceptDataSharingNotice() {
        setAcceptedDataNotShared(true);
    }

    const fetchPreviousScans = () => {
        setLoading(true);
        fetch(composeUrl('v1/healthTracker'), {
            headers: [['Authorization', `Bearer ${auth.user?.access_token}`]],
            method: 'GET'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                startAutoUpdate(data.nextAllowedScanDate);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    React.useEffect(() => {
        fetchPreviousScans();
    }, []);

    function calculateTimeDifference(targetDate: Date): { days: number, hours: number, minutes: number } {
        const now = new Date();
        const diff = new Date(targetDate).getTime() - now.getTime();

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

        return {days, hours, minutes};
    }

    function startAutoUpdate(targetDate: Date) {
        if (targetDate !== undefined && targetDate !== null) {
            const updateDisplay = () => {
                const {days, hours, minutes} = calculateTimeDifference(targetDate);
                if (!(days < 0 || hours < 0 || minutes < 0)) {
                    setNextAllowedScan(`Your next scan is available in ${days} days ${hours} hours ${minutes} minutes`);
                }
            };

            updateDisplay();
            setInterval(updateDisplay, 60000);
        }
    }

    return (
        <div className="lg:p-xl">
            {
                (!acceptedDataNotShared) &&
                <div>
                    <div className="hidden sm:flex">
                        {HealthTrackerBanner()}
                    </div>
                    <div className="p-4">
                        <div className="flex flex-col sm:flex-row text-center sm:text-start sm:pb-xl">
                            <div className="flex flex-col order-1 justify-center sm:order-2 sm:basis-4/5 sm:px-md">
                                <span className="text-[2em] sm:text-[2.5em] text-sherpa-blue">
                                    <span
                                        className="text-[1.2em] text-sherpa-blue"><strong>{auth.user?.profile.nickname}</strong>
                                    </span>,&nbsp;
                                    <br className={"sm:hidden"}/>
                                    <strong>rest assured</strong>, your personal health data will&nbsp;
                                    <br className={"sm:hidden"}/>
                                    <strong>NEVER</strong>&nbsp;
                                    <br className={"sm:hidden"}/>
                                    be shared with your employer
                                </span>
                            </div>
                            <div
                                className="flex justify-center items-center w-full my-lg order-2 sm:order-1 sm:basis-1/5">
                                <img src={imgHandsAroundShield} alt="sometext"
                                     className="h-[8em] sm:h-[10em]"/>
                            </div>
                        </div>
                        <div className="sm:flex sm:flex-row place-content-center">
                            <div
                                onClick={acceptDataSharingNotice}
                                className="flex rounded-xl bg-lime-orange p-4 font-medium items-center
                            space-x-4 cursor-pointer justify-center hover:no-underline h-full sm:w-[14.875em] text-center"
                            >
                            <span className="text-h1 flex flex-row align-middle">
                                <strong>NEXT</strong>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                (acceptedDataNotShared) &&
                <div>
                    {HealthTrackerBanner()}
                    <div className="flex flex-col md:flex-row md:gap-xl md:my-xl overflow-x-hidden">
                        <div id="htInfoMessage" className="flex flex-col sm:basis-3/4 sm:gap-lg xl:justify-between">
                            <div
                                className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-center mb-md gap-sm">
                                <div className="flex flex-shrink-0 ml-[-1em] mt-[-2em]">
                                    <img src={imgDoctor}
                                         alt="Image of doctor with raised hand"
                                         className="h-[13.6em] w-[10em] sm:h-[17em] sm:w-[14em]"/>
                                </div>
                                <div className="ml-[-3em] sm:text-[1.25em]">
                                    <div className="">
                                        The Health Tracker is <strong>not a substitute for the clinical judgement of a
                                        health care professional</strong>. It’s intended to provide you with information
                                        to monitor your health through a monthly facial scan.
                                    </div>
                                    <br/>
                                    <div className="ml-[-6.2em] mt-[-1em] sm:m-0">
                                        The Health Tracker <strong>does not diagnose, treat, prevent any diseases,
                                        symptoms or disorders</strong>. You should <strong>consult a health care
                                        professional</strong> or the emergency services if you believe you have a
                                        medical issue.
                                    </div>
                                </div>
                            </div>
                            <div className="sm:flex sm:flex-row sm:gap-xl">
                                <div className="mb-md md:m-0 sm:basis-1/2">
                                    <div className="md:h-full">
                                        <div
                                            onClick={navigateToHome}
                                            rel="noreferrer"
                                            className="flex flex-col rounded-xl bg-lime-orange p-4 sm:p-[2em] font-medium items-center
                                            cursor-pointer hover:no-underline h-full text-center"
                                        >
                                            <span className="text-[1.4em] sm:text-[2em] sm:pb-2 flex flex-row">
                                                <strong>
                                                    TAKE YOUR&nbsp;
                                                    <br className="hidden sm:flex"/>
                                                    MONTHLY SCAN
                                                </strong>
                                            </span>
                                            <span className="flex flex-row text-p3 sm:text-[1em]">
                                                {nextAllowedScan}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-md md:m-0 sm:basis-1/2">
                                    <div className="md:h-full">
                                        <div
                                            onClick={navigateToPreviousResults}
                                            rel="noreferrer"
                                            className="flex flex-col rounded-xl bg-barley-white p-4 sm:p-[2em] font-medium items-center
                                            cursor-pointer hover:no-underline h-full text-center"
                                        >
                                            <span className="text-[1.4em] sm:text-[2em] flex flex-row">
                                                <strong>
                                                    VIEW YOUR
                                                    <br className="hidden sm:flex"/>
                                                    PREVIOUS RESULT
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="htInfoDocs" 
                             className="flex flex-row sm:flex-col md:basis-1/4 gap-sm sm:gap-xl items-stretch basis-1/4">
                            <div className="flex flex-col basis-1/3">
                                <a
                                    href={faqsUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                    space-x-4 p-7 sm:p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                                    <div 
                                        className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                        justify-between items-center ">
                                        <span 
                                            className="text-lime-blue leading-5 text-[1.25em] sm:text-[1.875em]
                                                      sm:me-md md:me-0 md:mb-md md:text-center">
                                            FAQ
                                        </span>
                                        <img src={imgInfo} alt="info" className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                    </div>
                                </a>
                            </div>
                            <div className="flex flex-col gap-sm sm:gap-xl basis-2/3">
                                <a
                                    href={summaryUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                    <div
                                        className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                        justify-between items-center ">
                                        <span className="text-lime-blue  text-[1.25em] sm:text-[1.875em]
                                                        sm:me-md md:me-0 md:mb-md md:text-center">
                                            Service Summary
                                        </span>
                                        <img src={imgInfo} alt="info" className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                    </div>
                                </a>
                                <div
                                    onClick={navigateToDocs}
                                    className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                                    <div
                                        className="w-full h-full flex flex-col sm:flex-row sm:justify-center md:flex-col
                                        justify-between items-center ">
                                        <span className="text-lime-blue leading-5 text-[1.25em] sm:text-[1.875em]
                                                        sm:me-md md:me-0 md:mb-md md:text-center">
                                            Documents
                                        </span>
                                        <img src={imgInfo} alt="info" className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ProductInfo;