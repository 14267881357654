import React from 'react';
import DocumentItem from "./DocumentItem";
import { ProductIds } from "../../models/ProductEnums";
import useProducts from "../../utils/useProducts";

function Documents() {

    const { products, loading } = useProducts();

    const hasHealthTracker = products.some(product => product.productUuid === ProductIds.HealthTracker);
    const hasFastAnswers = products.some(product => product.productUuid === ProductIds.HealthEssentials);
    const hasCancerNavigator = products.some(product => product.productUuid === ProductIds.CancerNavigator);

  return (
      <div id="pageDocuments" className="w-full">
        <h2 className="lg:text-h1">Documents</h2>
        <h4 className="lg:text-h3 mb-1">All</h4>
        <table className="border-t border-mid-grey w-full">
          <thead className="text-left text-p2 lg:text-h1">
          <tr>
            <th className="py-sm lg:text-h4 w-32 lg:w-36">Download</th>
            <th className="py-sm lg:text-h4">Name</th>
          </tr>
          </thead>
          <tbody>
          <DocumentItem name="Lime Terms and Conditions"
                        href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/MemberTermsAndConditions.pdf"/>
          <DocumentItem name="Lime Privacy Policy"
                        href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/MemberPrivacyPolicy.pdf"/>
          {(!loading && hasHealthTracker) && (
              <>
                  <DocumentItem name="Health Tracker Service Summary" 
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+Service+Summary.pdf"/>
                  <DocumentItem name="Health Tracker FAQs"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+FAQ.pdf"/>
                  <DocumentItem name="Health Tracker Privacy Policy"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+Privacy+Policy.pdf"/>
              </>
          )}
          {(!loading && hasFastAnswers) && (
              <>
                  <DocumentItem name="Health Essentials Service Summary"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Essentials+Service+Summary.pdf"/>
                  <DocumentItem name="Health Essentials FAQ"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Essentials+FAQs.pdf"/>
              </>
          )}
          {(!loading && hasCancerNavigator) && (
              <>
                  <DocumentItem name="Cancer Navigator Service Summary"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/MemberCancerNavigatorServiceSummary.pdf"/>
                  <DocumentItem name="Cancer Navigator Insurance Product Information Document"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/MemberCancerNavigatorIPID.pdf"/>
                  <DocumentItem name="Cancer Navigator FAQs"
                                href="https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Cancer+Navigator+FAQs.pdf"/>
              </>
          )}
          </tbody>
        </table>
      </div>
    );
}

export default Documents;