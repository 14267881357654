import React from "react";
import imgMobileFaceScan from "../../images/health-tracker/face-scan-icon.svg"
import imgOnCall from "../../images/dashboard/oncall.png";
import imgScoreDial from "../../images/dashboard/tracker_icon.png";

function HealthTrackerBanner() {
    return (
        <div
            className="flex p-md sm:py-2 sm:px-7 gap-md sm:gap-lg rounded-xl justify-between items-stretch bg-lime-fg w-full mb-lg">
            <div className="flex flex-col basis-2/3 sm:basis-3/5 gap-md order-1 sm:order-2">
                    <span className="text-sherpa-blue text-[1.125em] sm:text-p0 sm:font-medium sm:p-xl">
                        Monitor your health, take a <strong>monthly</strong> scan and see your current scores.
                    </span>
            </div>
            <div className="flex flex-col gap-md order-2 sm:order-1">
                <img src={imgMobileFaceScan}
                     alt="Mobile phone camera doing a face scan"
                     className="h-[5em] sm:w-[6.5em] sm:h-[11.6em]"/>
            </div>
            <div className="hidden lg:flex lg:flex-col justify-center order-3">
                <div
                    className={`hidden sm:flex sm:ml-[2em] py-[0.4em] w-full my-sm`}>
                    <div className={`relative`}>
                        <h3
                            className={`text-sherpa-blue bg-moss-green m-0 py-[0.4em] pl-[2.1em] pr-[1.3em] font-sans-co font-bold text-[1em] sm:text-[2.5em] rounded-2xl items-center`}>
                                <span
                                    className={`w-[2.8em] h-[2.8em] -top-[0.4em] -left-[0.8em] rounded-full bg-moss-green absolute flex items-center justify-center`}>
                                    <img src={imgScoreDial} alt="Health Tracker logo"
                                         className="w-[1.8em] h-[1.8em] z-20"/>
                                </span> Health Tracker
                        </h3>
                    </div>
                </div>
                <div className={`flex place-content-end -mt-sm`}>
                    <img src={imgOnCall}
                         alt="OnCall"
                         className=" h-[4.375em] w-[12.125em]"/>
                </div>
            </div>
        </div>
    )
}

export default HealthTrackerBanner;