import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import {composeUrl} from "../../utils/api";
import RadioGroup from "../Shared/Radio/TraditionalRadioGroup";
import imgInfo from "../../images/info_icon.png";
import imgAlarmClock from "../../images/health-tracker/green-alarm-clock.png";
import imgNoGlassesSign from "../../images/health-tracker/green-no-glasses-sign.png";
import imgNoTalkingSign from "../../images/health-tracker/green-no-talking-sign.png";
import imgRecliningOnChair from "../../images/health-tracker/green-reclining-on-chair.png";
import imgWarningExclamation from "../../images/health-tracker/green-warning-exclamation.png";
import score from "../../images/dashboard/tracker_icon.png";
import alulaLogo from "../../images/health-tracker/alula-logo.svg";
import Checked from "../../images/checkbox-checked.svg";
import Unchecked from "../../images/checkbox-unchecked.svg";
import HealthTrackerBanner from "./HealthTrackerBanner";

enum GenderAtBirth {
    Male = 1,
    Female = 2
}

enum DiabetesStatus {
    No = 1,
    Type1 = 2,
    Type2 = 3
}

interface CreateScanFormData {
    age: number | undefined;
    gender: GenderAtBirth | undefined;
    height: number | undefined;
    weight: number | undefined;
    diabetesStatus: DiabetesStatus | undefined;
    usesBloodPressureMedication: boolean | undefined;
    isSmoker: boolean | undefined;
}

function Home() {
    const auth = useAuth();
    const navigate = useNavigate();

    const navigateToProductInfo = () => navigate("/HealthTracker", {state: {showHealthTrackerHome: false}});
    const navigateToDocs = () => navigate("/documents");
    const faqsUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+FAQ.pdf";
    const privacyPolicy = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+Privacy+Policy.pdf";
    const summaryUrl = "https://lime-public.s3.eu-west-1.amazonaws.com/web/Members/Documents/Health+Tracker+Service+Summary.pdf";

    const [acceptedPrepareForScan, setAcceptedPrepareForScan] = useState<boolean>(false);
    const [acceptedConsentNotice, setAcceptedConsentNotice] = useState<boolean>(false);

    const {handleSubmit, formState} = useForm<CreateScanFormData>();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const [genderAtBirth, setGenderAtBirth] = useState<GenderAtBirth>();
    const [diabetesStatus, setDiabetesStatus] = useState<DiabetesStatus>();
    const [usesBloodPressureMedication, setUsesBloodPressureMedication] = useState<boolean>();
    const [isSmoker, setIsSmoker] = useState<boolean>();
    const [age, setAge] = useState<number>();
    const [height, setHeight] = useState<number>();
    const [weight, setWeight] = useState<number>();

    const handleGenderAtBirth = (selectedOption: string) => {
        const selectedOptionEnum = GenderAtBirth[selectedOption as keyof typeof GenderAtBirth];
        setGenderAtBirth(selectedOptionEnum);
    };

    const handleDiabetesStatus = (selectedOption: string) => {
        const selectedOptionEnum = DiabetesStatus[selectedOption as keyof typeof DiabetesStatus];
        setDiabetesStatus(selectedOptionEnum);
    };

    const handleUsesBloodPressureMedication = (selectedOption: string) => {
        setUsesBloodPressureMedication(selectedOption === "true");
    };

    const handleIsSmoker = (selectedOption: string) => {
        setIsSmoker(selectedOption === "true");
    };

    const acceptConsentNotice = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setAcceptedConsentNotice(isChecked);
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseFloat(event.target.value);
        const input = event.target.id;

        switch (input) {
            case "age":
                setAge(newValue);
                break;
            case "height":
                setHeight(newValue);
                break;
            case "weight":
                setWeight(newValue);
                break;
        }
    };

    const submitButtonDisabled = () => {
        return (validateNumber(age)
            || validateNumber(height)
            || validateNumber(weight)
            || genderAtBirth === undefined
            || diabetesStatus === undefined
            || usesBloodPressureMedication === undefined
            || isSmoker === undefined
            || !acceptedConsentNotice);
    };
    
    const validateNumber = (value?: number) => {
        return (
            value === undefined ||
            isNaN(value) ||
            value <= 0
        );
    }

    const onSubmit = (data: CreateScanFormData) => {
        setSubmitLoading(true);

        let createScanFormData: CreateScanFormData = {
            age: age,
            gender: genderAtBirth,
            height: height,
            weight: weight,
            diabetesStatus: diabetesStatus,
            usesBloodPressureMedication: usesBloodPressureMedication,
            isSmoker: isSmoker
        };

        fetch(composeUrl(`v1/healthTracker`),
            {
            headers: [
                ["Authorization", `Bearer ${auth.user?.access_token}`],
                ["Content-Type", "application/json"]
            ],
            method: "POST",
            body: JSON.stringify(createScanFormData)
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                if (data.assesmentUrl !== undefined) {
                    // open scan page using returned url in new tab
                    window.open(data.assesmentUrl, "_self");
                }
            })
            .catch(error => {
                console.error("There was a problem with the fetch operation:", error);
            })
            .finally(() => {
                setSubmitLoading(false);
            });
    };

    function acceptPrepareForScan() {
        setAcceptedPrepareForScan(true);
    }

    return (
        <div className="flex flex-col p-1 gap-xl sm:p-xl">
            <div className="hidden sm:flex">
                {HealthTrackerBanner()}
            </div>
            <div className="flex flex-col sm:flex-row sm:gap-xl">
                <div className="flex flex-col sm:basis-3/5 sm:gap-lg h-full">
                    {
                        (!acceptedPrepareForScan) &&
                        <div className="flex font-medium justify-center mb-md gap-sm sm:gap-xl h-full">
                            <div className="flex flex-col space-y-4">
                                <div className="text-center sm:text-start pb-2">
                                    <strong className="text-[1.75em] leading-5 text-sherpa-blue">
                                        Preparing for face scan
                                    </strong>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-md sm:gap-xl h-full">
                                    <div className="flex gap-md items-center">
                                        <img
                                            src={imgWarningExclamation}
                                            alt="Green circle containing a warning triangle with an exclamation symbol"
                                            className="w-[3.75em] h-[3.75em]"
                                        />
                                        <span className="text-p1 leading-[1.375em]">
                                            <strong>Do not smoke, exercise or consume caffeine or alcohol</strong> for 30 minutes to an hour before the scan
                                        </span>
                                    </div>
                                    <div className="flex gap-md items-center">
                                        <img
                                            src={imgRecliningOnChair}
                                            alt="Green circle containing a person reclining on a chair"
                                            className="w-[3.75em] h-[3.75em]"
                                        />
                                        <span className="text-p1 leading-[1.375em]">
                                            <strong>Relax</strong> in a chair for at least <strong>five minutes before</strong> the test
                                        </span>
                                    </div>
                                    <div className="flex gap-md items-center">
                                        <img
                                            src={imgNoTalkingSign}
                                            alt="Green circle containing a no talking sign"
                                            className="w-[3.75em] h-[3.75em]"
                                        />
                                        <span className="text-p1 leading-[1.375em]">
                                            <strong>Don’t talk</strong> during the scan
                                        </span>
                                    </div>
                                    <div className="flex gap-md items-center">
                                        <img
                                            src={imgAlarmClock}
                                            alt="Green circle containing an alarm clock"
                                            className="w-[3.75em] h-[3.75em]"
                                        />
                                        <span className="text-p1 leading-[1.375em]">
                                            Ideally - take face scan the <strong>same time of the day each month</strong> eg. mid morning or mid afternoon
                                        </span>
                                    </div>
                                    <div className="flex gap-md items-center">
                                        <img
                                            src={imgNoGlassesSign}
                                            alt="Green no glasses sign"
                                            className="w-[3.75em] h-[3.75em]"
                                        />
                                        <span className="text-p1 leading-[1.375em]">
                                            If you wear <strong>glasses</strong>, you will need <strong>to</strong> remove for the face scan
                                        </span>
                                    </div>
                                    <div className="flex flex-row gap-md items-center">
                                        <div className="flex flex-col basis-1/4 sm:basis-0">
                                            <a
                                                href={faqsUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="flex sm:hidden rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                                space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                                visited:text-lime-bg active:text-lime-bg font-sans-co h-full"
                                            >
                                                <div
                                                    className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                    <span className="text-lime-blue leading-5 text-[1.25em]">
                                                        FAQ
                                                    </span>
                                                    <img
                                                        src={imgInfo}
                                                        alt="info"
                                                        className="w-[1.25em] h-[1.25em]]"
                                                    />
                                                </div>
                                            </a>
                                        </div>
                                        <div className="flex flex-col basis-3/4 sm:basis-full">
                                            <div className="align-items-center">
                                                <div
                                                    onClick={acceptPrepareForScan}
                                                    className="flex rounded-xl bg-lime-orange p-2 font-medium items-center
                                                    space-x-4 cursor-pointer justify-center hover:no-underline h-full text-center  w-full"
                                                >
                                                    <span className="text-h1 flex flex-row align-middle">
                                                        <strong>NEXT</strong>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        (acceptedPrepareForScan) &&
                        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-md sm:gap-xl">
                            <div className="space-y-4 md:space-y-0 md:flex md:space-x-8">
                                <div id="htFormInputs" className="md:flex md:flex-col md:gap-md md:w-1/2 space-y-4">
                                    <div className="flex flex-row gap-md items-center">
                                        <p className="flex-none w-32">Enter your age</p>
                                        <div className="flex-grow">
                                            <input
                                                className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                placeholder="Enter age"
                                                type="number"
                                                id="age"
                                                name="age"
                                                min={1}
                                                max={120}
                                                onChange={handleNumberChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-md items-center">
                                        <p className="flex-none w-32">Height (in cm)</p>
                                        <div className="flex-grow">
                                            <input
                                                className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                placeholder="Height (cm)"
                                                type="number"
                                                id="height"
                                                name="height"
                                                min={1}
                                                max={300}
                                                onChange={handleNumberChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-md items-center">
                                        <p className="flex-none w-32">Weight (in kg)</p>
                                        <div className="flex-grow">
                                            <input
                                                className="border border-solid border-primary-text rounded-md py-sm md:py-md px-sm w-full"
                                                placeholder="Weight (kg)"
                                                type="number"
                                                id="weight"
                                                name="weight"
                                                min={1}
                                                max={500}
                                                onChange={handleNumberChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div id="htFormRadios" className="md:flex md:flex-col md:gap-md md:w-1/2 space-y-4">
                                    <div className="flex flex-row items-center w-full gap-md">
                                        <p className="flex-grow">Gender at birth</p>
                                        <RadioGroup
                                            options={[
                                                {name: "genderAtBirth", value: "Male", label: "Male"},
                                                {name: "genderAtBirth", value: "Female", label: "Female"}
                                            ]}
                                            onChange={handleGenderAtBirth}
                                            containerClassName="flex-shrink-0 justify-end"
                                            optionClassName="ml-md"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center w-full gap-md">
                                        <p className="flex-grow">Do you use medication to control Blood Pressure?</p>
                                        <RadioGroup
                                            options={[
                                                {name: "usesBloodPressureMedication", value: "false", label: "No"},
                                                {name: "usesBloodPressureMedication", value: "true", label: "Yes"}
                                            ]}
                                            onChange={handleUsesBloodPressureMedication}
                                            containerClassName="flex-shrink-0 justify-end"
                                            optionClassName="ml-md"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center w-full gap-md">
                                        <p className="flex-grow">Are you a smoker?</p>
                                        <RadioGroup
                                            options={[
                                                {name: "isSmoker", value: "false", label: "No"},
                                                {name: "isSmoker", value: "true", label: "Yes"}
                                            ]}
                                            onChange={handleIsSmoker}
                                            containerClassName="flex-shrink-0 justify-end"
                                            optionClassName="ml-md"
                                        />
                                    </div>
                                    <div className="flex flex-row items-center w-full gap-md">
                                        <p className="flex-grow">Diabetes status</p>
                                        <RadioGroup
                                            options={[
                                                {name: "diabetesStatus", value: "No", label: "No"},
                                                {name: "diabetesStatus", value: "Type1", label: "Type 1"},
                                                {name: "diabetesStatus", value: "Type2", label: "Type 2"}
                                            ]}
                                            onChange={handleDiabetesStatus}
                                            containerClassName="flex-shrink-0 justify-end"
                                            optionClassName="ml-md"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-4 md:space-y-0 md:flex md:space-x-8">
                                <div
                                    className="flex flex-row rounded-xl bg-lime-disclaimer p-4 font-medium justify-items-start gap-md md:basis-2/3">
                                    <label key="true" className="flex items-start">
                                        <input
                                            type="checkbox"
                                            id="acceptConsentNotice"
                                            onChange={acceptConsentNotice}
                                            className="appearance-none"
                                        />
                                        <img
                                            key="true"
                                            src={acceptedConsentNotice ? Checked : Unchecked}
                                            alt="accepted"
                                            className="flex items-start max-w-max max-h-max"
                                        />
                                    </label>
                                    <span>
                                        <strong>I consent</strong> to Lime International Health Ltd sharing my personal health data with Alula Technologies where this is necessary to provide the Health Tracker Service. 
                                        Please see{" "}
                                        <a
                                            href={privacyPolicy}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="font-bold cursor-pointer hover:no-underline visited:text-lime-bg active:text-lime-bg"
                                        >
                                            Alula's Privacy Policy
                                        </a>{" "}
                                        for more info.
                                    </span>
                                </div>
                                <div className="flex flex-row gap-md items-center md:basis-1/3">
                                    <div className="sm:hidden flex flex-col basis-1/4 sm:basis-0 h-full">
                                        <a
                                            href={faqsUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                            space-x-4 p-2 cursor-pointer justify-between hover:no-underline
                                            visited:text-lime-bg active:text-lime-bg font-sans-co h-full py-md"
                                        >
                                            <div
                                                className="flex flex-col justify-between items-center w-full h-full gap-sm">
                                                <span className="text-lime-blue leading-5 text-[1.25em]">
                                                    FAQ
                                                </span>
                                                <img
                                                    src={imgInfo}
                                                    alt="info"
                                                    className="w-[1.25em] h-[1.25em]]"
                                                />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="flex flex-col basis-3/4 sm:basis-full h-full">
                                        <div className="align-items-center h-full">
                                            <button
                                                className="flex rounded-xl bg-lime-orange p-2 font-medium items-center border-none
                                                space-x-4 cursor-pointer justify-center hover:no-underline h-full w-full text-center text-primary-text"
                                                disabled={
                                                    submitLoading || (submitButtonDisabled() ?? false)
                                                }
                                                onClick={undefined}
                                            >
                                                <span className="text-h1 flex flex-row align-middle">
                                                    <strong>SCAN NOW</strong>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                </div>
                <div className="hidden sm:flex flex-row sm:flex-col gap-sm sm:gap-xl items-stretch basis-1/4 ml-auto">
                    <div className="flex flex-col basis-1/3">
                        <a
                            href={faqsUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="flex rounded-xl bg-lime-fg font-bold text-lime-bg items-center
                                    space-x-4 p-7 sm:p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co w-full h-full">
                            <div
                                className="flex flex-col justify-center items-center w-full h-full">
                                <span
                                    className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                    FAQ
                                </span>
                                <img src={imgInfo} alt="info" className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col gap-sm sm:gap-xl basis-2/3">
                        <a
                            href={summaryUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="flex rounded-xl bg-lime-turk font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                            <div
                                className="flex flex-row sm:flex-col justify-center items-center w-full h-full text-center">
                                <span className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                    Service Summary
                                </span>
                                <img src={imgInfo} alt="info" className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                            </div>
                        </a>
                        <div
                            onClick={navigateToDocs}
                            className="flex rounded-xl bg-quartz font-bold text-lime-bg items-center
                                    space-x-4 p-3 cursor-pointer justify-between hover:no-underline
                                    visited:text-lime-bg active:text-lime-bg font-sans-co h-full">
                            <div
                                className="flex flex-row sm:flex-col justify-center items-center w-full h-full">
                                <span className="text-lime-blue text-[1.25em] sm:text-[1.875em]">
                                    Documents
                                </span>
                                <img src={imgInfo} alt="info" className="w-10 h-10 md:w-20 md:h-20 lg:w-18 lg:h-18 mt-md xl:mt-sm"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"mt-auto"}>
                <div
                    className="flex flex-row rounded-xl bg-lime-fg p-4 items-center justify-between gap-md mt-sm max-h-[3em] sm:hidden align-bottom">
                    <div className={`ps-[0.1em] pe-0 py-[0.4em] w-full basis-1/2`}>
                        <div className={`relative`}>
                            <h3
                                className={`text-sherpa-blue bg-moss-green m-0 py-[0.4em] pl-[2em] font-sans-co font-bold text-[1em] rounded-xl  items-center`}
                            >
                            <span
                                className={`w-[2.4em] h-[2.4em] -top-[0.2em] -left-[0.4em] rounded-full bg-moss-green absolute flex items-center justify-center`}
                            >
                                <span
                                    className={"z-10 w-[1.4em] h-[1.4em] rounded-full absolute top-[0.5em] left-[0.5em] bg-lime-green"}
                                ></span>
                                <img
                                    src={score}
                                    alt="Health Tracker logo"
                                    className="w-[1.4em] h-[1.4em] z-20"
                                />
                            </span>{" "}
                                Health Tracker
                            </h3>
                        </div>
                    </div>
                    <span className="text-[0.54em] flex items-center">Powered by</span>
                    <img
                        src={alulaLogo}
                        alt={`Alula Technologies logo`}
                        className={`h-[1.6em]`}
                    />
                </div>
            </div>
        </div>
    );
}

export default Home;
