import React from 'react';
import {useNavigate} from "react-router-dom";



const errorMessages: { [key: string]: string } = {
    MEASUREMENT_TIMED_OUT: "You have taken too long to start your scan and it has timed out, please try again.",
    MEASUREMENT_CANCELED: "You cancelled your scan, please try again.",
    TOKEN_EXPIRED: "You have taken too long to start your scan, please try again.",
    CAMERA_PERMISSION_NOT_GRANTED: "Your camera permission was not granted, please try again and when prompted allow camera access.",
    SNR: "Your light conditions are insufficient for a good scan, please try again in better lighting.",
    INVALID_BMI: "You entered an invalid weight and height combination to calculate your BMI, please enter the details again.",
    INTERRUPTED: "Your scan was interrupted, please try again.",
    FACEMISSING: "We could not detect your face within the scan circle, please try again.",
    LOW_SNR: "Your light conditions are insufficient for a good scan, please try again in better lighting.",
    INVALID_HEIGHT: "You entered an invalid height, please enter the details again.",
    FACEOFFTARGET: "Your face was not centred in the scan circle, please position your face in the centre of the circle and try again.",
    INVALID_AGE: "You entered an invalid age, please enter the details again.",
    SCAN_CANCELLED: "Your scan was cancelled, please try again.",
    CAMERA_PERMISSION_DENIED: "Your camera permission was not granted, please try again and when prompted allow camera access.",
    NETWORK: "We detected a network error, please check your internet and try again.",
    UNKNOWN: "We could not complete your scan due to unforeseen circumstances, please try again.",
    SCAN_NOT_ALLOWED: "You are currently restricted from performing a new scan, please try again later."
};

interface HealthTrackerResultsIncompleteScanProps {
    errorCode?: string;
}

const HealthTrackerResultsIncompleteScan: React.FC<HealthTrackerResultsIncompleteScanProps> = ({errorCode}) => {
    const errorMessage = errorCode && errorMessages[errorCode]
        ? errorMessages[errorCode]
        : "Your scan could not be completed.";
    const navigate = useNavigate();
    const navigateToHome = () => navigate("/HealthTracker", {state: {showHealthTrackerHome: true}});
    
    return (
        <div className="flex flex-col items-center justify-center h-full">
            <h2 className="text-lg font-bold mb-4">Incomplete Face Scan</h2>
            <p className="text-sm mb-xl">{errorMessage}</p>
            <div>
                <div onClick={navigateToHome}
                    className="flex rounded-xl bg-lime-orange p-4 font-medium items-center space-x-4 
                    cursor-pointer justify-center hover:no-underline h-full sm:w-[14.875em] text-center">
                    <span className="text-h1 flex flex-row align-middle">
                        <strong>Try again</strong>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default HealthTrackerResultsIncompleteScan;
