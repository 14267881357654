import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Nudge, PageData, Measurement} from './HealthTrackerConstants';
import HealthScoreOverallGauge from './healthScoreOverallGuage';
import HealthScoreNudges from './HealthScoreNudges';
import HealthScoreBiometricsLinks from './HealthScoreBiometricsLinks';
import HealthScoreBiometrics from './HealthScoreBiometrics';
import HealthEssentialsCta from './HealthEssentialsCta';
import LoadingSpinner from '../Shared/LoadingSpinner/LoadingSpinner';
import {composeUrl} from '../../utils/api';
import {useAuth} from 'react-oidc-context';
import HealthTrackerNoData from './HealthTrackerNoData';
import HealthTrackerResultsIncompleteScan from './HealthTrackerResultsIncompleteScan';

function HealthTrackerResults() {
    const [loading, setLoading] = useState<boolean>(true);
    const [pageData, setPageData] = useState<PageData | null>(null);
    const auth = useAuth();

    useEffect(() => {
        const pathname = window.location.pathname;
        const pathParts = pathname.split('/');
        const guid = pathParts[pathParts.length - 1];

        if (guid) {
            const fetchData = async () => {
                try {
                    const response = await fetch(composeUrl(`v1/healthTracker/${guid}`), {
                        headers: {
                            Authorization: `Bearer ${auth.user?.access_token}`,
                            'Content-Type': 'application/json',
                        },
                        method: 'GET',
                    });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setPageData(data || null);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, [auth.user?.access_token]);

    const healthScore = pageData?.healthScore;
    const measurements: Measurement[] = (pageData?.measurements as Measurement[]) || [];

    let content;

    if (loading) {
        content = null;
    } else if (pageData === null) {
        content = <HealthTrackerNoData/>;
    } else if (!healthScore || measurements.length === 0) {
        content = <HealthTrackerResultsIncompleteScan/>;
    } else {
        const healthScoreValue = healthScore?.score || 65;
        const benchmark = healthScore?.benchmark || '';
        const calculationDate = pageData?.scanDate || '';
        const nudges: Nudge[] = (healthScore?.healthScoreNudges as Nudge[]) || [];

        content = (
            <>
                <div className="flex flex-col lg:flex-row">
                    <div id="healthScoreOverallGauge" className="mb-lg w-full lg:w-[40%]">
                        <HealthScoreOverallGauge
                            value={healthScoreValue}
                            benchmark={benchmark}
                            calculationDate={calculationDate}
                        />
                    </div>
                    <div className="mb-lg w-full lg:w-[60%]">
                        <div className="flex flex-col lg:flex-row">
                            <div id="healthScoreBiometricsLinks" className="mb-lg w-full lg:w-[60%]">
                                <HealthScoreBiometricsLinks measurements={measurements}/>
                            </div>
                            <div id="healthScoreNudges" className="mb-lg w-full lg:w-[40%]">
                                <HealthScoreNudges nudges={nudges}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="healthScoreBiometrics" className="mb-xl w-full">
                    <HealthScoreBiometrics measurements={measurements}/>
                </div>
                <div id="healthEssentialsCta" className="mb-xl w-full md:w-1/2">
                    <HealthEssentialsCta/>
                </div>
            </>
        );
    }

    return <LoadingSpinner loading={loading}>{content}</LoadingSpinner>;
}

export default HealthTrackerResults;
